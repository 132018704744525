import React, { useState } from 'react'
import { connect } from 'react-redux'
import { categoryReciver } from '../../constant/BroadcastConstant'
import { Action, Close, Division, LangActionSuccessMessage, LangActiveMonth, LangAdd, LangAddeddValue, LangBulkActionWarning, LangDeletedReason, LangDivision, LangEmployeeCanReplacePermitHour, LangExceptionReceiver, LangLeaveExpiredAt, LangLeaveRemainingDay, LangMonth, LangNo, LangProcess, LangReceiver, LangApplyPermitHourDeductionSetting, LangSpesificReceiver, LangSubstract, LangSubstractionValue, LangYes } from '../../constant/languange'
import { addAnnualLeaveAction, bulkActions, changeGroupAction, leaveInHourRequestSetAction, massDeleteAction, setAnunalLeaveAction, substractAnnualLeaveAction } from '../../constant/PersonnelConstant'
import { axiosCompany, checkAuthorization, loadDivisionOptions, loadPersonnelOptions, mapStateToProps, SpinnerButton, takeOptionsValue } from '../../helpers/Common'
import { AsyncSelectInput, InputDatePicker } from '../../helpers/Input'

function PersonnelBulkActionComponent({ redux, onClose, ...props }) {
  const initData = {
    action: '',
    target_type: '',
    target: null,
    target_id: [],
    except: null,
    exclude_id: []
  }
  const [data, setData] = useState(initData)
  const [errors, setError] = useState({ fields: [], message: '' })
  const [control, setControl] = useState({ showSuccessAlert: false, showLoad: false })

  const postBulkActionService = async () => {
    setControl(control => ({ ...control, showLoad: true }))
    formData()
    try {
      // eslint-disable-next-line
      const res = await axiosCompany().post('personnels/bulk-action', data)
      setError({ fields: [], message: '' })
      setControl(control => ({ ...control, showSuccessAlert: true, showLoad: false }))
    } catch (e) {
      setError(error => {
        error.fields = e.response.data.errors ? e.response.data.errors : { 0: [e.response.statusText] }
        error.message = e.response.data.message ? e.response.data.message : ''
        return { ...error }
      })
      setControl(control => ({ ...control, showLoad: false }))
    }
  }

  const formData = () => {
    data.target_id = data.target ? takeOptionsValue(data.target) : []
    data.exclude_id = data.except ? takeOptionsValue(data.except) : []
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setData(data => {
      data[name] = value
      return { ...data }
    })
  }

  const handleReceiverSelect = (e) => {
    const { name, value } = e.target
    setData(data => {
      data[name] = value
      data.target = null
      data.except = null
      data.exclude_id = []
      data.target_id = []
      return { ...data }
    })
  }

  const handleActionSelect = (e) => {
    const { name, value } = e.target
    setData(data => {
      const actionData = {
        annual_leave_set: setAnunalLeaveAction,
        annual_leave_substract: substractAnnualLeaveAction,
        annual_leave_add: addAnnualLeaveAction,
        change_group: changeGroupAction,
        mass_delete: massDeleteAction,
        leave_in_hour_request_set: leaveInHourRequestSetAction
      }[value]

      return {
        [name]: value,
        target_type: data.target_type,
        target: data.target,
        except: data.except,
        exclude_id: data.exclude_id,
        target_id: data.target_id,
        ...actionData
      }
    })
  }

  const onSubmit = () => {
    postBulkActionService()
  }

  const loadPersonnelGroupFilterOptions = (inputValue) => {
    return new Promise((resolve) => {
      axiosCompany()
        .get(`personnels?page=null&group_id=${data.target ? takeOptionsValue(data.target) : ''}&keyword=${inputValue}`)
        .then((result) => {
          resolve(result.data.map((item) => {
            let label = item.name
            if (item.group) {
              label += ' - ' + item.group.name
            }
  
            return { label, value: item.id }
          }))
        })
    })
  }

  return (
    <>
      <div className='form-group row'>
        <label className='col-sm-3 col-form-label'>Target <span style={{ color: 'red', fontSize: 20 }}>*</span></label>
        <div className='col-sm-9'>
          <select className={`form-control ${errors.fields.target_type ? 'is-invalid' : ''}`} name='target_type' value={data.target_type} onChange={(e) => { handleReceiverSelect(e) }}>
            <option value=''>{`-- ${LangReceiver} --`}</option>
            {
              categoryReciver.filter((item) => {
                return (!redux.profile.supervisor_groups || (redux.profile.supervisor_groups && item.value !== 'all'))
              }).map((item, i) => (<option key={i} value={item.value}>{item.label}</option>))
            }
          </select>
          {errors.fields.target_type ? (<div className='invalid-feedback'>{errors.fields.target_type[0]}</div>) : (null)}
          {(data.target_type !== '') && (
            <>
              {
                    (data.target_type !== 'all') && (
                      <div className='form-group row mt-3'>
                        <label className='col-sm-4 col-form-label'>{LangSpesificReceiver} <span style={{ color: 'red', fontSize: 20 }}>*</span></label>
                        <div className='col-sm-8'>
                          {
                            data.target_type === 'groups' && (
                              <AsyncSelectInput
                                isLoading={false}
                                isClearable
                                isMulti
                                loadOptions={loadDivisionOptions}
                                value={data.target}
                                error={errors.fields.target_id}
                                onChange={(value) => { setData({ ...data, target: value }) }}
                              />
                            )
                          }
                          {
                            (data.target_type === 'personnels' || data.target_type === 'all') && (
                              <AsyncSelectInput
                                isLoading={false}
                                isClearable
                                isMulti
                                loadOptions={loadPersonnelOptions}
                                error={errors.fields.target_id}
                                value={data.target}
                                onChange={(value) => { setData({ ...data, target: value }) }}
                              />
                            )
                          }
                          {errors.fields.target_id ? (<div style={{ fontSize: '80%' }} className='txt-danger mt-1'>{errors.fields.target_id[0]}</div>) : (null)}
                        </div>
                      </div>
                    )
                  }
              {(data.target_type !== 'personnels') && (
                <div className='form-group row mt-3'>
                  <label className='col-sm-4 col-form-label'>{LangExceptionReceiver}</label>
                  <div className='col-sm-8'>
                    {
                      (data.target_type === 'all') && (
                        <AsyncSelectInput
                          isLoading={false}
                          isClearable
                          isMulti
                          loadOptions={loadPersonnelOptions}
                          value={data.except}
                          onChange={(value) => { setData({ ...data, except: value }) }}
                        />
                      )
                    }
                    {
                      (data.target_type === 'groups') && (
                        <AsyncSelectInput
                          key={data.target ? takeOptionsValue(data.target) : 'select-exception'}
                          isLoading={false}
                          isClearable
                          isMulti
                          loadOptions={loadPersonnelGroupFilterOptions}
                          value={data.except}
                          onChange={(value) => { setData({ ...data, except: value }) }}
                        />
                      )
                    }
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-sm-3 col-form-label'>{Action} <span style={{ color: 'red', fontSize: 20 }}>*</span></label>
        <div className='col-sm-9'>
          <select name='action' className={`form-control ${errors.fields.action ? 'is-invalid' : ''}`} value={data?.action} onChange={(e) => { handleActionSelect(e) }}>
            <option value=''>--{Action}--</option>
            {
              !redux.profile.supervisor_groups ?
                bulkActions.map((obj, i) => {
                  if (obj.val === 'mass_delete') {
                    if (!checkAuthorization(redux, 'employee.delete')) {
                      return
                    } else {
                      return (<option key={i} value={obj.val}>{obj.name}</option>)
                    }
                  } else {
                    if (!checkAuthorization(redux, 'employee.update')) {
                      return
                    } else {
                      return (<option key={i} value={obj.val}>{obj.name}</option>)
                    }
                  }
                })
                :
                bulkActions.map((obj, i) => {
                  if (obj.val !== 'mass_delete') return (<option key={i} value={obj.val}>{obj.name}</option>)
                })
            }
          </select>
          {errors.fields.action ? (<div className='invalid-feedback'>{errors.fields.action[0]}</div>) : (null)}
          {data?.action && <div className='alert alert-warning mt-3 mb-0 txt-dark'>{LangBulkActionWarning}</div>}
        </div>
      </div>
      <div className='form-group row'>
        <div className='col-sm-3' />
        <div className='col-sm-9'>
          {
            {
              annual_leave_set: (
                <>
                  <div className='form-group row'>
                    <label className='col-sm-4 col-form-label'>{LangLeaveRemainingDay} <span style={{ color: 'red', fontSize: 20 }}>*</span></label>
                    <div className='col-sm-8 pt-1'>
                      <input name='set_value' style={{ maxWidth: 120 }} className={`form-control ${errors.fields.set_value ? 'is-invalid' : ''}`} type='text' value={data?.set_value} error={errors.fields.set_value} onChange={(e) => { handleInputChange(e) }} />
                      {errors.fields.set_value ? (<div className='invalid-feedback'>{errors.fields.set_value[0]}</div>) : (null)}
                    </div>
                  </div>
                  <div className='form-group row'>
                    <label className='col-sm-4 col-form-label'>{LangLeaveExpiredAt}</label>
                    <div className='col-sm-8 pt-1'>
                      <InputDatePicker value={data?.set_expired_at} error={errors.fields.set_expired_at} onChange={(value) => { setData({ ...data, set_expired_at: value }) }} />
                      {errors.fields.set_expired_at ? (<div className='invalid-feedback'>{errors.fields.set_expired_at[0]}</div>) : (null)}
                    </div>
                  </div>
                </>
              ),
              annual_leave_add: (
                <>
                  <div className='form-group row'>
                    <label className='col-sm-4 col-form-label'>{LangAddeddValue} <span style={{ color: 'red', fontSize: 20 }}>*</span></label>
                    <div className='col-sm-8 pt-1'>
                      <input name='add_value' style={{ maxWidth: 120 }} className={`form-control ${errors.fields.add_value ? 'is-invalid' : ''}`} type='text' value={data?.add_value} error={errors.fields.add_value} onChange={(e) => { handleInputChange(e) }} />
                      {errors.fields.add_value ? (<div className='invalid-feedback'>{errors.fields.add_value[0]}</div>) : (null)}
                    </div>
                  </div>
                  <div className='form-group row'>
                    <label className='col-sm-4 col-form-label'>{LangAdd} {LangActiveMonth}</label>
                    <div className='col-sm-3 pt-1'>
                      <div className='d-flex align-items-center'>
                        <div className='flex-grow-1'>
                          <input name='add_expired_month' className={`form-control ${errors.fields.add_expired_month ? 'is-invalid' : ''}`} type='text' value={data?.add_expired_month} error={errors.fields.add_expired_month} onChange={(e) => { handleInputChange(e) }} />
                        </div>
                        <div className='ml-2'>{LangMonth}</div>
                      </div>
                      {errors.fields.add_expired_month ? (<div className='invalid-feedback'>{errors.fields.add_expired_month[0]}</div>) : (null)}
                    </div>
                  </div>
                </>
              ),
              annual_leave_subtract: (
                <>
                  <div className='form-group row'>
                    <label className='col-sm-4 col-form-label'>{LangSubstractionValue} <span style={{ color: 'red', fontSize: 20 }}>*</span></label>
                    <div className='col-sm-8 pt-1'>
                      <input name='subtract_value' style={{ maxWidth: 120 }} className={`form-control ${errors.fields.subtract_value ? 'is-invalid' : ''}`} type='text' value={data?.subtract_value} error={errors.fields.subtract_value} onChange={(e) => { handleInputChange(e) }} />
                      {errors.fields.subtract_value ? (<div className='invalid-feedback'>{errors.fields.subtract_value[0]}</div>) : (null)}
                    </div>
                  </div>
                  <div className='form-group row'>
                    <label className='col-sm-4 col-form-label'>{LangSubstract} {LangActiveMonth}</label>
                    <div className='col-sm-3 pt-1'>
                      <div className='d-flex align-items-center'>
                        <div className='flex-grow-1'>
                          <input name='subtract_expired_month' className={`form-control ${errors.fields.subtract_expired_month ? 'is-invalid' : ''}`} type='text' value={data?.subtract_expired_month} error={errors.fields.subtract_expired_month} onChange={(e) => { handleInputChange(e) }} />
                        </div>
                        <div className='ml-2'>{LangMonth}</div>
                      </div>
                      {errors.fields.subtract_expired_month ? (<div className='invalid-feedback'>{errors.fields.subtract_expired_month[0]}</div>) : (null)}
                    </div>
                  </div>
                </>
              ),
              change_group: (
                <>
                  <div className='form-group row'>
                    <label className='col-sm-4 col-form-label'>{LangDivision} <span style={{ color: 'red', fontSize: 20 }}>*</span></label>
                    <div className='col-sm-8 pt-1'>
                      <select name='change_group_value' value={data.change_group_value} className={`form-control ${errors.fields.change_group_value ? 'is-invalid' : ''}`} onChange={(e) => { handleInputChange(e) }}>
                        <option value=''>--{Division}--</option>
                        {redux.groups.map((obj) => {
                          return (
                            <option key={obj.id} value={obj.id}>{obj.name}</option>
                          )
                        })}
                      </select>
                      {errors.fields.change_group_value ? (<div className='invalid-feedback'>{errors.fields.change_group_value[0]}</div>) : (null)}
                    </div>
                  </div>
                </>
              ),
              mass_delete: (
                <div className='form-group row'>
                  <label className='col-sm-4 col-form-label'>{LangDeletedReason}</label>
                  <div className='col-sm-8 pt-1'>
                    <input name='deleted_reason' className={`form-control ${errors.fields.deleted_reason ? 'is-invalid' : ''}`} type='text' value={data?.deleted_reason} error={errors.fields.deleted_reason} onChange={(e) => { handleInputChange(e) }} />
                    {errors.fields.deleted_reason ? (<div className='invalid-feedback'>{errors.fields.deleted_reason[0]}</div>) : (null)}
                  </div>
                </div>
              ),
              leave_in_hour_request_set: (
                <>
                  <div className='form-group row mt-3'>
                    <label className='col-sm-5'>{LangEmployeeCanReplacePermitHour}</label>
                    <div className='col-sm-7'>
                      <div className='form-check form-check-inline'>
                        <input className='form-check-input' type='checkbox' id='can_replace1' checked={data.can_replace === 1} onChange={() => setData({ ...data, can_replace: 1 })} />
                        <label className='form-check-label' htmlFor='can_replace1'>{LangYes.toUpperCase()}</label>
                      </div>
                      <div className='form-check form-check-inline'>
                        <input className='form-check-input' type='checkbox' id='can_replace2' checked={data.can_replace === 0} onChange={() => setData({ ...data, can_replace: 0 })} />
                        <label className='form-check-label' htmlFor='can_replace2'>{LangNo.toUpperCase()}</label>
                      </div>
                      {errors.fields.can_replace && (<div class='invalid-feedback d-block'>{errors.fields.can_replace}</div>)}
                    </div>
                  </div>
                  <div className='form-group row'>
                    <label className='col-sm-5'>{LangApplyPermitHourDeductionSetting}</label>
                    <div className='col-sm-7'>
                      <div className='form-check form-check-inline'>
                        <input className='form-check-input' type='checkbox' id='is_apply_deduction1' checked={data.is_apply_deduction === 1} onChange={() => setData({ ...data, is_apply_deduction: 1 })} />
                        <label className='form-check-label' htmlFor='is_apply_deduction1'>{LangYes.toUpperCase()}</label>
                      </div>
                      <div className='form-check form-check-inline'>
                        <input className='form-check-input' type='checkbox' id='is_apply_deduction2' checked={data.is_apply_deduction === 0} onChange={() => setData({ ...data, is_apply_deduction: 0 })} />
                        <label className='form-check-label' htmlFor='is_apply_deduction2'>{LangNo.toUpperCase()}</label>
                      </div>
                      {errors.fields.is_apply_deduction && (<div class='invalid-feedback d-block'>{errors.fields.is_apply_deduction}</div>)}
                    </div>
                  </div>
                </>
              )
            }[data.action]
          }
        </div>
      </div>
      <hr />
      <div className='form-group row'>
        <div className='col-9 offset-3'>
          {errors.message ? <div className='alert alert-danger'>{errors.message}</div> : null}
          {control.showSuccessAlert ? <div className='alert alert-success'>{LangActionSuccessMessage}</div> : null}
          <button disabled={control.showLoad} className='btn  btn-primary mr-2' onClick={onSubmit}> {(control.showLoad) ? SpinnerButton : ''} {LangProcess}</button>
          <button disabled={control.showLoad} className='btn  btn-light' type='button' onClick={() => { onClose() }}>
            {Close}
          </button>

        </div>
      </div>
    </>
  )
}

export default connect(mapStateToProps)(PersonnelBulkActionComponent)
