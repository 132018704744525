import { connect, displayDate, durationFromSeconds, formatFieldValue, mapDispatchToProps, mapStateToProps, moment, toRupiah } from '../../helpers/Common'
import { groupFieldKeysArray, personnelFieldKeysArray } from '../../constant/HistoryFieldLanguageConstant'
import { Day, Hours, LangActive, LangAutomatic, LangContinuedOtherDay, LangCutiTahunan, LangDay, LangDayOff, LangDays, LangDone, LangEmpty, LangInReview, LangLeaveApproved, LangLeaveRejected, LangManual, LangMaxApprovalBefore, LangMaxApprovalH, LangMonth, LangNo, LangNonActive, LangNotActive, LangNotSetYet, LangNotStarted, LangOnProcess, LangPending2, LangReminderDayInfo, LangSpecialLeave, LangTimes, LangUnpaidLeave, LangYes, Minutes, Seconds, WorkingDay } from '../../constant/languange'
import { overtimeType, salaryType } from '../../constant/PayrollConstants'
import { genderType } from '../../constant/PersonnelConstant'
import { statusName } from '../../constant/CommonConstant'
import { categoryReciver, statusBroadcast } from '../../constant/BroadcastConstant'
import Message from './history-component/broadcast/Message'
import Incomes from './history-component/payroll/Incomes'
import Deductions from './history-component/payroll/Deductions'
import Schedules from './history-component/work-pattern/Schedules'
import EmergencyContacts from './history-component/personnel/EmergencyContacts'
import AutoReloadAnnualLeave from './history-component/personnel/AutoReloadAnnualLeave'
import Components from './history-component/personnel/Components'
import Personnels from './history-component/spot/Personnels'
import Groups from './history-component/spot/Groups'
import AdditionalFields from './history-component/client/additionalFields'
import ApprovalSteps from './history-component/leave-request/ApprovalSteps'
import ApprovalRulesStep from './history-component/approval-rules/ApprovalRulesStep'
import ApprovalRulesTarget from './history-component/approval-rules/ApprovalRulesTarget'
import BPJSConfig from './history-component/personnel/BPJSConfig'
import PositionAttendanceTarget from './history-component/position/PositionAttendanceTarget'

const activationFormat = (data, key) => {
  return formatFieldValue(data[key], (val) => { return +val ? LangActive : LangNonActive })
}

const currencyFormat = (data, key) => {
  return formatFieldValue(data[key], (val) => { return `Rp${toRupiah(val)}` })
}

const dateFormat = (data, key, format) => {
  return formatFieldValue(data[key], (val) => { return val ? displayDate(val, format ?? 'DD MMM YYYY') : null })
}

const dateTimeFormat = (data, key, format) => {
  return formatFieldValue(data[key], (val) => { return val ? displayDate(val, format ?? 'DD MMM YYYY @ HH:mm') : null })
}

const durationFormat = (data, key, format) => {
  return formatFieldValue(data[key], (val) => { 
    let formatted = ''
    if (val === 0) {
      formatted = 0
    } else {
      formatted = moment.utc(val * 1000).format(`HH [${Hours}] mm [${Minutes}] ss [${Seconds}]`)
    }
    return val !== null ? formatted  : null 
  })
}

const minuteFormat = (data, key) => {
  return formatFieldValue(data[key], (val) => { return `${val} min` })
}

const hourStringFormat = (data, key, currentFormat, newFormat) => {
  return formatFieldValue(data[key], (val) => { return val ? moment(val, currentFormat ?? 'HH:mm:ss').format(newFormat ?? 'HH:mm') : null })
}

export const fileIdentityFormat = (data, key) => {
  return formatFieldValue(data[key], (val) => { return val })
}

/**
 * Personnel Document Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const personnelDocumentFormat = (data, key) => {
  const formatData = {
    expired_at:  () => { return dateFormat(data, key) },
    reminder_days:  () => {
      return formatFieldValue(data[key], (val) => `${val} ${LangReminderDayInfo}`)
    },
    is_enable_reminder: () => {
      return formatFieldValue(data[key], (val) => `${val ? LangYes : LangNo}`)
    }
  }

  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * payroll bonus Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const payrollFieldFormat = (data, key) => {
  const formatData = {
    incomes: () => {
      return formatFieldValue(data[key], (val) => {
        return (
          <>
            {val?.length > 0
              ? (<Incomes val={val} />)
              : LangNotSetYet}
          </>

        )
      })
    },
    deductions: () => {
      return formatFieldValue(data[key], (val) => {
        return (
          <>
            {val?.length > 0
              ? (<Deductions val={val} />)
              : LangNotSetYet}
          </>

        )
      })
    },
    total_income: () => { return currencyFormat(data, key) },
    total_deduction: () => { return currencyFormat(data, key) },
    thp: () => { return currencyFormat(data, key) }
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Personnel Field Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const personnelFieldFormat = (data, key) => {
  const formatFieldDate = [
    'leave_expired_at',
    'start_work'
  ].reduce((curr, item) => ({ ...curr, [item]: () => { return dateFormat(data, key) } }), {})

  const formatFieldActivation = [
    'is_repeat_shift_schedule'
  ].reduce((curr, item) => ({ ...curr, [item]: () => { return activationFormat(data, key) } }), {})

  const formatFieldCurrency = personnelFieldKeysArray.slice(personnelFieldKeysArray.findIndex(key => key === 'rate'), personnelFieldKeysArray.findIndex(key => key === 'deduction_leave_in_hour') + 1).reduce((curr, item) => ({ ...curr, [item]: () => { return currencyFormat(data, key) } }), {})

  const formatData = {
    gender: () => { return formatFieldValue(data[key], (val) => { return genderType[val] ?? null }) },
    type_id: () => { return formatFieldValue(data[key], (val) => { return salaryType[+val - 1] ?? null }) },
    overtime_type_id: () => { return formatFieldValue(data[key], (val) => { return overtimeType[+val - 1] ?? null }) },
    emergency_contacts: () => {
      return formatFieldValue(data[key], (val) => {
        return (
          <>
            {val?.length > 0
              ? (<EmergencyContacts val={val} />)
              : LangNotSetYet}
          </>

        )
      })
    },
    auto_reload_annual_leave: () => {
      return formatFieldValue(data[key], (val) => {
        return (<AutoReloadAnnualLeave val={val} />)
      })
    },
    // rincian gaji
    components: () => {
      return formatFieldValue(data[key], (val) => {
        return (
          <>
            {val?.length > 0
              ? (<Components val={val} />)
              : LangNotSetYet}
          </>

        )
      })
    },
    deduction_uncompleted_worktime_rate: () => { return formatFieldValue(data[key], (val) => { return 'Rp' + toRupiah(String(val).replace(/[^0-9]*/g, '')) }) },
    deduction_absence_rate: () => { return formatFieldValue(data[key], (val) => { return 'Rp' + toRupiah(String(val).replace(/[^0-9]*/g, '')) }) },
    deduction_absence_model: () => { return formatFieldValue(data[key], (val) => { return val === 2 ? LangManual : LangAutomatic }) },
    bpjs_config: () => {
      return formatFieldValue(data[key], (val) => {
        return (
          <>
            {val
              ? (<BPJSConfig val={val} />)
              : LangNotSetYet}
          </>
        )
      })
    },
    ...formatFieldDate,
    ...formatFieldActivation,
    ...formatFieldCurrency
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Group Field Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const groupFieldFormat = (data, key) => {
  const formatFieldMinute = groupFieldKeysArray.slice(groupFieldKeysArray.findIndex(key => key === 'limit_attendance_1_after_minute'), groupFieldKeysArray.findIndex(key => key === 'auto_clock_out_after_minute') + 1).reduce((curr, item) => ({ ...curr, [item]: () => { return minuteFormat(data, key) } }), {})

  const formatFieldActivation = [
    'late_punishment',
    ...groupFieldKeysArray.slice(groupFieldKeysArray.findIndex(key => key === 'must_on_spot_1'), groupFieldKeysArray.findIndex(key => key === 'out_of_spot') + 1),
    ...groupFieldKeysArray.slice(groupFieldKeysArray.findIndex(key => key === 'spv_can_view_selfie'), groupFieldKeysArray.findIndex(key => key === 'client_visit_enable') + 1)
  ].reduce((curr, item) => ({ ...curr, [item]: () => { return activationFormat(data, key) } }), {})

  const formatData = {
    penalty_not_clock_out_enable: () => { return formatFieldValue(data[key], (val) => { return val === "1" ? LangActive : LangNonActive }) },
    penalty_not_clock_out_after_minute: () => { return minuteFormat(data, key) },
    penalty_not_clock_out_nominal: () => { return formatFieldValue(data[key], (val) => { return 'Rp' + toRupiah(String(val).replace(/[^0-9]*/g, '')) }) },
    ...formatFieldActivation,
    ...formatFieldMinute
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Group Late Field Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const groupLateFieldFormat = (data, key) => {
  const formatData = {
    is_active: () => { return activationFormat(data, key) },
    minute: () => { return minuteFormat(data, key) },
    penalty: () => { return currencyFormat(data, key) }
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Position Field Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const positionFieldFormat = (data, key) => {
  const formatData = {
    targets: () => {
      return formatFieldValue(data[key], (val) => {
        return (
          <>
            {val?.length > 0
              ? (<PositionAttendanceTarget val={val} />)
              : LangNotSetYet}
          </>
        )
      })
    }
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Work Time Field Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const workTimeFieldFormat = (data, key) => {
  const formatData = {
    start_date: () => { return formatFieldValue(data[key], (val) => { return val ? displayDate(val, 'DD MMM YYYY') : null }) },
    'workPattern.tardiness_tolerance': () => { return minuteFormat(data, key) }
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Work Shift Field Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const workShiftFieldFormat = (data, key) => {
  const formatFieldTime = [
    'shiftWorkPattern.time_in',
    'shiftWorkPattern.time_out'
  ].reduce((curr, item) => ({ ...curr, [item]: () => { return hourStringFormat(data, key) } }), {})

  const formatData = {
    is_day_off: () => { return formatFieldValue(data[key], (val) => { return val ? LangDayOff : WorkingDay }) },
    'shiftWorkPattern.tardiness_tolerance': () => { return minuteFormat(data, key) },
    date_time_out: () => { return formatFieldValue(data[key], (val) => { return val ? displayDate(val, 'DD MMM YYYY') : null }) },
    ...formatFieldTime
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Spot Field Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const spotFieldFormat = (data, key) => {
  const formatData = {
    radius: () => { return formatFieldValue(data[key], (val) => { return `${val} meter` }) },
    personnels: () => {
      return formatFieldValue(data[key], (val) => {
        return (
          <>
            {val?.length > 0
              ? (<Personnels val={val} />)
              : LangNotSetYet}
          </>

        )
      })
    },
    groups: () => {
      return formatFieldValue(data[key], (val) => {
        return (
          <>
            {val?.length > 0
              ? (<Groups val={val} />)
              : LangNotSetYet}
          </>

        )
      })
    }
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Shift Pattern Field Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const shiftPatternFieldFormat = (data, key) => {
  const formatFieldTime = [
    'time_in',
    'time_out'
  ].reduce((curr, item) => ({ ...curr, [item]: () => { return hourStringFormat(data, key) } }), {})

  const formatData = {
    tardiness_tolerance: () => { return minuteFormat(data, key) },
    color: () => {
      return formatFieldValue(data[key], (val) => {
        return (
          <div className='img-20 rounded-circle d-inline-block' style={{ backgroundColor: val }}>&nbsp;</div>
        )
      })
    },
    ...formatFieldTime
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Work Pattern Field Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const workPatternFieldFormat = (data, key) => {
  const formatData = {
    tardiness_tolerance: () => { return minuteFormat(data, key) },
    schedules: () => {
      return formatFieldValue(data[key], (val) => {
        return (<Schedules val={val} />)
      })
    }
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Client Field Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const clientFieldFormat = (data, key) => {
  const formatData = {
    client_visit_must_on_spot: () => { return formatFieldValue(data[key], (val) => { return val ? LangYes : LangNo }) },
    radius: () => { return formatFieldValue(data[key], (val) => { return val + ' Meter' }) },
    additional_fields: () => {
      return formatFieldValue(data[key], (val) => {
        return (
          <>
            {val?.length > 0
              ? <AdditionalFields val={val} />
              : LangNotSetYet}
          </>

        )
      })
    }
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Client Visit Schedule Field Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const clientVisitScheduleFieldFormat = (data, key) => {
  const formatData = {
    'client.radius': () => { return formatFieldValue(data[key], (val) => { return `${val} meter` }) },
    client_visit_must_on_spot: () => { return formatFieldValue(data[key], (val) => { return val ? LangYes : LangNo }) },
    date:  () => { return dateFormat(data, key) },
    start_time:  () => { return formatFieldValue(data[key], (val) => { return `${val ? val.substring(0, 5) : ''}` }) },
    end_time:  () => { return formatFieldValue(data[key], (val) => { return `${val ? val.substring(0, 5) : ''}` }) },
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Attendance Field Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const attendanceFieldFormat = (data, key) => {
  const formatFieldTime = [
    'total_work_minute',
    'total_overtime_minute',
    'total_late_minute',
    'total_break_minute'
  ].reduce((curr, item) => ({ ...curr, [item]: () => { return formatFieldValue(data[key], (val) => { return val > 0 ? durationFromSeconds(val * 60) : val }) } }), {})

  const formatFieldTimeSecond = [
    'late_seconds',
    'late_tolerance_seconds'
  ].reduce((curr, item) => ({ ...curr, [item]: () => { return formatFieldValue(data[key], (val) => { return val > 0 ? durationFromSeconds(val) : val }) } }), {})

  const formatData = {
    mark_approve: () => { return formatFieldValue(data[key], (val) => { return +val ? LangLeaveApproved : LangInReview }) },
    penalty_not_clock_out_fee: () => { return formatFieldValue(data[key], (val) => { return 'Rp' + toRupiah(String(val).replace(/[^0-9]*/g, '')) }) },
    tardiness_tolerance: () => { return minuteFormat(data, key) },
    log_date_time: () => { return dateFormat(data, key, 'DD MMM YYYY @ HH:mm') },
    penalty_late_fee: () => { return currencyFormat(data, key) },
    rejected_at: () => { return dateFormat(data, key, 'DD MMM YYYY @ HH:mm') },
    ...formatFieldTimeSecond,
    ...formatFieldTime

  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Leave & Annual Leave Field Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const permitLeaveFieldFormat = (data, key) => {
  const formatData = {
    status: () => { return formatFieldValue(data[key], (val) => { return statusName[val] ?? null }) },
    dates: () => {
      return formatFieldValue(data[key], (val) => {
        return (
          <div className='table-responsive my-3 text-center'>
            <table className='table table-sm'>
              <tbody>
                {val.length !== 0 ? (val.map((item, i) => (
                  <tr key={i}>
                    <td>{displayDate(item, 'DD MMM YYYY')}</td>
                  </tr>
                ))) : (
                  <tr>{LangEmpty}</tr>
                )}
              </tbody>
            </table>
          </div>
        )
      })
    },
    approval_steps: () => {
      return formatFieldValue(data[key], (val) => {
        return (
          <>
            {val?.length > 0
              ? (<ApprovalSteps val={val} />)
              : LangNotSetYet}
          </>

        )
      })
    },
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Leave Request Date Field Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const leaveRequestDateFieldFormat = (data, key) => {
  const formatData = {
    date: () => { return dateFormat(data, key) }
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Attendance Edit Request Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const attendanceEditRequestFieldFormat = (data, key) => {
  const formatData = {
    status: () => { return formatFieldValue(data[key], (val) => { return statusName[val] ?? null }) }
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Holiday Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const holidayFieldFormat = (data, key) => {
  const formatData = {
    date: () => { return dateFormat(data, key) }
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Broadcast Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const broadcastFieldFormat = (data, key) => {
  const formatFieldHtml = [
    'message_content',
    'message_content_en'
  ].reduce((curr, item) => ({
    ...curr,
    [item]: () => {
      return formatFieldValue(data[key], (val) => (<Message val={val} />))
    }
  }), {})

  const formatFieldTarget = [
    'target_id',
    'except_id'
  ].reduce((curr, item) => ({
    ...curr,
    [item]: () => {
      return formatFieldValue(data[key], (val) => { return val && val.length > 0 ? val.join() : null })
    }
  }), {})

  const formatData = {
    status: () => { return formatFieldValue(data[key], (val) => { return statusBroadcast[val].name ?? null }) },
    target_type: () => { return formatFieldValue(data[key], (val) => { return categoryReciver.slice().find(item => item.value === val)?.label ?? null }) },
    ...formatFieldHtml,
    ...formatFieldTarget
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * approval Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const approvalFieldFormat = (data, key) => {
  const formatData = {
    approved_at: () => { return dateFormat(data, key) },
    date: () => { return dateFormat(data, key) },
    is_approved: () => { return formatFieldValue(data[key], (val) => { return +val ? LangLeaveApproved : LangInReview }) }

  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Reimbursement Request Field Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
const ExpensesComponent = connect(mapStateToProps, mapDispatchToProps)(
  ({ val, redux }) => {
    return (
      <ul className='list-group' style={{ width: 'fit-content' }}>
        {val.map((item, idx) => (
          <li key={idx} className={`list-group-item px-2 py-1 ${redux.darkModeOn ? 'bg-dark' : ''}`}>
            {item.name} 
            <br />Rp{toRupiah(item.nominal ?? '')}
          </li>
        ))}
      </ul>
    )
  }
)

export const reimbursementRequestFieldFormat = (data, key) => {
  const formatData = {
    expenses: () => {
      return formatFieldValue(data[key], (val) => {
        return <ExpensesComponent val={val} />
      })
    },
    status: () => {
      return formatFieldValue(data[key], (val) => {
        if (val === 'pending') return LangInReview
        if (val === 'approved') return LangLeaveApproved
        if (val === 'rejected') return LangLeaveRejected
      })
    },
    approved_at: () => { return dateFormat(data, key) },
    rejected_at: () => { return dateFormat(data, key) },
    request_date: () => { return dateFormat(data, key) },
    request_amount: () => {
      return formatFieldValue(data[key], (val) => `Rp${toRupiah(val)}`)
    },
    approval_amount: () => {
      return formatFieldValue(data[key], (val) => `Rp${toRupiah(val)}`)
    },
    add_to_personnel_payroll: () => { 
      return formatFieldValue(data[key], (val) => { return val ? LangYes : LangNo }) 
    }
  }

  return formatData[key] ? formatData[key]() : data[key]
}

export const reimbursementFieldFormat = (data, key) => {
  const formatData = {
    effective_date: () => { return dateFormat(data, key) },
    expired_in:  () => {
      return formatFieldValue(data[key], (val) => `${val} ${LangMonth}`)
    },
    min_next_claim:  () => {
      return formatFieldValue(data[key], (val) => `${val} ${LangMonth}`)
    },
    max_req_amount: () => {
      return formatFieldValue(data[key], (val) => `Rp${toRupiah(val)}`)
    },
    min_req_amount: () => {
      return formatFieldValue(data[key], (val) => `Rp${toRupiah(val)}`)
    },
    quota: () => {
      return formatFieldValue(data[key], (val) => `Rp${toRupiah(val)}`)
    }
  }

  return formatData[key] ? formatData[key]() : data[key]
}

export const reimbursementPersonnelFieldFormat = (data, key) => {
  const formatData = {
    expired_in:  () => { return dateFormat(data, key) },
    balance:  () => {
      return formatFieldValue(data[key], (val) => `Rp${toRupiah(val)}`)
    }
  }

  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * device approval Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const deviceApprovalFieldFormat = (data, key) => {
  const formatData = {
    status: () => { return formatFieldValue(data[key], (val) => { return statusName[val] ?? null }) }

  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * client visit Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const clientVisitFieldFormat = (data, key) => {
  const formatFieldDate = [
    'date_time_end',
    'date_time_start'
  ].reduce((curr, item) => ({ ...curr, [item]: () => { return dateFormat(data, key) } }), {})
  const formatData = {
    ...formatFieldDate
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * payroll deduction Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const payrollDeductionFieldFormat = (data, key) => {
  const formatData = {
    deduction_nominal: () => { return currencyFormat(data, key) }

  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * payroll bonus Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const payrollBonusFieldFormat = (data, key) => {
  const formatData = {
    bonus_nominal: () => { return currencyFormat(data, key) }

  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * leave hour request Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const leaveRequestHourFieldFormat = (data, key) => {
  const formatFieldDate = [
    'time_from',
    'time_to'
  ].reduce((curr, item) => ({ ...curr, [item]: () => { return dateFormat(data, key, 'HH:mm') } }), {})
  const formatData = {
    status: () => { return formatFieldValue(data[key], (val) => { return statusName[val] ?? null }) },
    is_fulfilled: () => { return formatFieldValue(data[key], (val) => { return val ? LangYes : LangNo }) },
    can_replace: () => { return formatFieldValue(data[key], (val) => { return val === 1 ? LangYes : LangNo }) },
    is_apply_deduction: () => { return formatFieldValue(data[key], (val) => { return val === 1 ? LangYes : LangNo }) },
    ...formatFieldDate
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Special Leave Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const specialLeaveFieldFormat = (data, key) => {
  const formatData = {
    is_active: () => { return formatFieldValue(data[key], (val) => { return val === 1 ? LangActive : LangNotActive }) },
    max_days: () => { return formatFieldValue(data[key], (val) => { return val ? val > 1 ? `${val} ${LangDays}` : `${val} ${LangDay}` : null }) },
    max_request_per_year: () => { return formatFieldValue(data[key], (val) => { return val ? `${val} ${LangTimes}` : null }) },
    max_days_per_year: () => { return formatFieldValue(data[key], (val) => { return val ? val > 1 ? `${val} ${LangDays}` : `${val} ${LangDay}` : null }) },
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Timesheet Activity Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const timesheetActivityFieldFormat = (data, key) => {
  const formatData = {
    status: () => {
      return formatFieldValue(data[key], (val) => {
        return <>
          {
            val === 'not_started' && <u>{LangNotStarted}</u>
          }
          {
            val === 'processed' && <u>{LangOnProcess}</u>
          }
          {
            val === 'pending' && <u>{LangPending2}</u>
          }
          {
            val === 'done' && <u>{LangDone}</u>
          }
          {
            val === 'continued_otherday' && <u>{LangContinuedOtherDay}</u>
          }
        </>
      })
    },
    date_time_start: () => {
      if (data.status !== 'not_started') {
        return dateTimeFormat(data, key) 
      }
    },
    date_time_end: () => { return dateTimeFormat(data, key) },
    duration: () => { return durationFormat(data, key) }
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Timesheet Project Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const timesheetProjectFieldFormat = (data, key) => {
  const formatData = {
    members: () => {
      return formatFieldValue(data[key], (val) => {
        return (
          <>
            {val?.length > 0
              ? (<Personnels val={val} />)
              : LangNotSetYet}
          </>

        )
      })
    },
    color: () => {
      return formatFieldValue(data[key], (val) => {
        return (
          <div className='img-20 rounded-circle d-inline-block' style={{ backgroundColor: val }}>&nbsp;</div>
        )
      })
    }
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Overtime Schedule Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const overtimeScheduleFieldFormat = (data, key) => {
  const formatData = {
    date_time_start: () => { return dateTimeFormat(data, key) },
    date_time_end: () => { return dateTimeFormat(data, key) },
    limit_attendance_before_minute: () => { return formatFieldValue(data[key], (val) => { return `${val} ${Minutes}` }) }
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Approval Rules Field Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const approvalRuleFieldFormat = (data, key) => {
  const formatData = {
    system_ids: () => { 
      return formatFieldValue(data[key], (val) => { 
        return val.map(item => item === 1 ? LangCutiTahunan : item === 2 ? LangSpecialLeave : item === 3 ? LangUnpaidLeave : '').join(', ')
      }) 
    },
    max_approval_days: () => { 
      return formatFieldValue(data[key], (val) => { 
        return val[0]?.type && val[0]?.type === 'max_h_plus' ? `${LangMaxApprovalH} ${val[0]?.value !== null ? `(${val[0]?.value} ${Day})` : ''}` : LangMaxApprovalBefore
      }) 
    },
    approval_steps: () => {
      return formatFieldValue(data[key], (val) => {
        return (
          <>
            {val?.length > 0
              ? (<ApprovalRulesStep val={val} />)
              : LangNotSetYet}
          </>
        )
      })
    },
    target: () => {
      return formatFieldValue(data[key], (val) => {
        return (
          <>
            {val?.length > 0
              ? (<ApprovalRulesTarget val={val} />)
              : LangNotSetYet}
          </>
        )
      })
    }
  }
  return formatData[key] ? formatData[key]() : data[key]
}

/**
 * Personnel Profile Picture Request Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const personnelProfilePictureRequestFieldFormat = (data, key) => {
  const formatData = {
    status: () => {
      return formatFieldValue(data[key], (val) => {
        if (val === 'pending') return LangInReview
        if (val === 'approve') return LangLeaveApproved
        if (val === 'reject') return LangLeaveRejected
      })
    },
  }
  return formatData[key] ? formatData[key]() : data[key]
}


/**
 * Pintu Kerjoo Attendance Approval Request Fields Format
 * ---------------------------------------------------------------------------------------------------------------------------------------------------------
 */
export const pintuKerjooApprovalRequestFieldsFormat = (data, key) => {
  const formatData = {
    approved_at: () => { return dateFormat(data, key) },
    log_date_time_local: () => { return formatFieldValue(data[key], (val) => { return val ? moment(val).format('DD MMM YYYY @ HH:mm') : null }) }
  }
  return formatData[key] ? formatData[key]() : data[key]
}
