import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Delete, LangDeleteEmployeeConfirm, LangDeleteEmployeeConfirm1, LangDeleteEmployeeConfirm2, LangDeleteEmployeeConfirm3, LangExample, LangImportCancel, LangNotNow, LangReason, LangResetDataFirst, LangResign, LangWrongInput, LangYesSure } from '../../constant/languange'
import { axiosCompany, mapDispatchToProps, mapStateToProps } from '../../helpers/Common'
import { SpinnerButton } from '../../helpers/Spinner'
import ReactTooltip from 'react-tooltip'
import { ModalApp } from '../../helpers/Input'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function PersonnelDeleteComponent({ redux, setState, currentData, getPersonnelsService, ...props }) {
  const [control, setControl] = useState({ showLoad: false })
  const [data, setData] = useState({ deleted_reason: '' })

  // function
  const deletePersonnelService = (e) => {
    e.preventDefault()
    if (redux.isTemporary) return

    const formData = new window.FormData()
    formData.append('deleted_reason', data.deleted_reason)
    formData.append('_method', 'delete')

    setControl(control => ({ ...control, showLoad: true }))
    axiosCompany().post(`personnels/${currentData.id}`, formData)
      .then(() => {
        getPersonnelsService()
        setControl(control => ({ ...control, showLoad: false }))
        props.handleClose()

        if (redux.stats.personnels_count > 0) {
          setState({ isUpdateStats: redux.isUpdateStats + 1 })
        }
      }).catch(e => {
        setControl(control => ({ ...control, showLoad: false }))
      })
  }

  // handler
  const handleTextChange = (e) => {
    setData(currData => ({ ...currData, [e.target.name]: e.target.value }))
  }

  return (
    <>
      <form className='form' onSubmit={(e) => { e.preventDefault(); setControl({ ...control, showModal: 'confirm' }) }} autoComplete='off'>
        <div className='form-group row'>
          <label className='col-sm-3 col-form-label text-lg-right'>{LangReason}</label>
          <div className='col-sm-8'>
            <input type='text' class='form-control' name='deleted_reason' value={data.deleted_reason} onChange={handleTextChange} />
            <span class='form-text text-muted'>{LangExample}: {' '}
              <ins style={{ cursor: 'pointer' }} onClick={() => setData(currData => ({ ...currData, deleted_reason: LangWrongInput }))}>{LangWrongInput}</ins> | {' '}
              <ins style={{ cursor: 'pointer' }} onClick={() => setData(currData => ({ ...currData, deleted_reason: LangResign }))}>{LangResign}</ins>
            </span>
          </div>
        </div>
        <hr />
        <div className='form-group row'>
          <div className='col-sm-9 offset-md-3'>
            <div>
              {
                redux.isTemporary ?
                  <>
                    <button disabled={true} data-for='tooltip-info-reset-data' data-tip={LangResetDataFirst} type='submit' className='btn btn-danger mr-2'>{(control.showLoad) ? SpinnerButton : null} {Delete}</button>
                    <ReactTooltip id='tooltip-info-reset-data' place='bottom' />
                  </>
                  :
                  <button disabled={control.showLoad} type='submit' className='btn btn-danger mr-2'>{(control.showLoad) ? SpinnerButton : null} {Delete}</button>
              }
              <button disabled={control.showLoad} onClick={() => props.handleClose()} type='button' className={`btn ${redux.darkModeOn ? 'btn-dark' : 'btn-light'}`}>{LangImportCancel}</button>
            </div>
          </div>
        </div>
      </form>
      
      {control.showModal === 'confirm' && (
        <ModalApp
          title={LangDeleteEmployeeConfirm}
          handleClose={() => { setControl({ ...control, showModal: '' }) }}
          size='md'
          body={
            <>
              {data.deleted_reason.trim() ? (
                <p style={{ fontSize: 16 }}>{LangDeleteEmployeeConfirm1} <b>{currentData.name}</b> {LangDeleteEmployeeConfirm2} <b>"{data.deleted_reason}"</b>?</p>
              ) : (
                <p style={{ fontSize: 16 }}>{LangDeleteEmployeeConfirm1} <b>{currentData.name}</b>?</p>
              )}
              <div className={`alert ${redux.darkModeOn ? 'alert-dark' : 'alert-light'} my-2`}>
                <FontAwesomeIcon icon={faInfoCircle} /> {LangDeleteEmployeeConfirm3}.
              </div>
              <hr />
              <div className='d-flex'>
                <button disabled={control.showLoad} className='btn btn-danger mr-2' type='button' onClick={deletePersonnelService}> {control.showLoad ? SpinnerButton : ''} {LangYesSure}</button>
                <button className='btn btn-light mr-2' type='button' onClick={() => { setControl({ ...control, showModal: '' }) }}>{LangNotNow}</button>
              </div>
            </>
          }
        />
      )}
    </>

  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonnelDeleteComponent)
