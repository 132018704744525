import React, { useEffect, useState } from 'react'
import { activityAction, activityLogsItem, isModifiedKeyList } from '../../constant/AdminActivityConstant'
import { LangActivity } from '../../constant/languange'
import { connect, mapStateToProps, axiosCompany, displayDate, PaginationDiv, SpinnerLoadFirst, axios, apiUrl, ShowImage, cookies } from '../../helpers/Common'

function ModalAdminActivityLogsComponent ({ redux, type, id, url, safeMode = false, ...props }) {
  const apiUrlRoute = url ?? '/activity-logs'

  const [control, setControl] = useState({ showLoad: false })
  const [personnelId, setPersonnelId] = useState([])

  const [filter] = useState({
    page: 1,
    action: '',
    subject_type: type ?? '',
    subject_id: id ?? '',
    by_user: null,
    by_user_id: '',
    date_from: null, // moment().subtract(2, 'month').format('YYYY-MM-DD'),
    date_to: null, // moment().format('YYYY-MM-DD')
    personnel_id: props.personnelId ?? '',
    schedule_date: props.scheduleDate ?? ''
  })

  const filterSafeMode = {
    page: 1,
    session_id: cookies.get('session_id')
  }

  const [data, setData] = useState({
    data: [],
    meta: {
      total: 0
    }
  })

  useEffect(() => {
    getListService()
    getPersonnelId()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    activityLogsItem['App\\TenantModels\\Personnel'].addtionalProps = { personnelId: personnelId }
  }, [personnelId])

  const getListService = () => {
    setControl(control => ({ ...control, showLoad: true }))
    axiosCompany().get(apiUrlRoute, { params: safeMode ? filterSafeMode : filter }).then(ress => {
      // data transform for certain component
      if (filter.subject_type === 'ApprovalRule') {
        const dataTransformApproval = ress.data.data.map((item) => {
          let newAttributes = {}
          let newOld = {}

          newAttributes = { ...item?.properties?.attributes, ...item?.properties?.attributes?.attr }
          newOld = { ...item?.properties?.old, ...item?.properties?.old?.attr }

          if (item.subject_type === 'App\\TenantModels\\ApprovalRule') {
            const maxApprovalDaysNew = item?.properties?.attributes?.max_approval_days
            const maxApprovalDaysOld = item?.properties?.old?.max_approval_days

            if (maxApprovalDaysNew) newAttributes = { ...newAttributes, max_approval_days: [maxApprovalDaysNew] }
            if (maxApprovalDaysOld) newOld = { ...newOld, max_approval_days: [maxApprovalDaysOld] }
          }

          const newProperties = { attributes: newAttributes, old: newOld ?? null }

          return { ...item, properties: newProperties }
        })
        setData({ ...ress.data, data: dataTransformApproval })
      } else if (filter.subject_type === 'Permit' || filter.subject_type === 'Personnel' || filter.subject_type === 'Group' || filter.subject_type === 'ClientVisitClient' || filter.subject_type === 'LeaveInHourRequest') {
        const dataTransform = ress.data.data.map(item => {
          let newAttributes = { ...item?.properties?.attributes, ...item?.properties?.attributes?.attr, ...item?.properties?.attributes?.attr?.payment_period }
          if (filter.subject_type === 'Personnel' && item?.properties?.attributes?.attr?.payment_period) newAttributes = { ...newAttributes, ...item?.properties?.attributes?.attr?.payment_period }
          if (filter.subject_type !== 'Personnel') delete newAttributes.attr

          let newOld = { ...item?.properties?.old, ...item?.properties?.old?.attr }
          if (filter.subject_type === 'Personnel' && item?.properties?.old?.attr?.payment_period) newOld = { ...newOld, ...item?.properties?.old?.attr?.payment_period }
          if (filter.subject_type !== 'Personnel') delete newOld.attr

          const newProperties = { attributes: newAttributes, old: newOld ?? null }

          return { ...item, properties: newProperties }
        })

        setData({ ...ress.data, data: dataTransform })
      } else {
        // for another component
        setData({ ...ress.data })
      }

      setControl(control => ({ ...control, showLoad: false, showModal: '', onFinishAction: false }))
    }).catch(e => {
      setControl(control => ({ ...control, showLoad: false }))
    })
  }

  const getPersonnelId = () => {
    axios.get(apiUrl + '/reference/personnel_identities')
      .then((result) => {
        setPersonnelId([...result.data])
      })
  }

  const parseToLogsFormat = (logData) => {
    let result = {}
    if (typeof logData?.properties?.attributes === 'object') {
      for (const key in logData?.properties?.attributes) {
        if (key) {
          result[key] = { after: logData?.properties?.attributes[key] ?? null, before: logData?.properties?.old[key] ?? null }
        }
      }
    } else {
      result = { after: logData?.properties?.attributes, before: logData?.properties?.old }
    }
    return result
  }

  const getLogsValue = (logData) => ({
    action_name: activityAction[logData?.description].old_val,
    by_user: logData?.user ?? { name: 'System' },
    data: (isModifiedKeyList.includes(logData?.description))
      ? { ...parseToLogsFormat(logData) }
      : { ...logData?.properties?.attributes }
  })

  const handlePage = (page) => {
    if (safeMode) filterSafeMode.page = page
    if (!safeMode) filter.page = page
    getListService()
  }

  return (
    <div className='card'>
      {control.showLoad
        ? (<div className=''>{SpinnerLoadFirst}</div>)
        : (
          <>
            <div className='table-responsive'>
              <table className={`table table-striped table-bordered ${redux.darkModeOn ? 'table-dark bg-dark' : ''}`}>
                <thead>
                  <tr>
                    <th scope='col' className='border-top-0 text-center align-middle'>User</th>
                    <th scope='col' className='border-top-0 text-center align-middle'>{LangActivity}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.data.map((item, i) => {
                    const { addtionalProps = null, ...subject } = activityLogsItem[item?.subject_type] ?? {}
                    const { label: actLabel = null, color: actColor = null } = activityAction[item?.description] ?? {}

                    return (
                      <tr key={i}>
                        <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`} style={{ maxWidth: 180, minWidth: 180 }}>
                          <div className='media'>
                            <ShowImage className='mr-3 img-40 rounded-circle' defaultpictureonload={1} />
                            <div className='media-body'>
                              <p className='my-0 f-w-600'>{item.user ? item?.user?.name : 'System'}</p>
                              <p className='my-0'>
                                {displayDate(item.created_at, 'DD MMM YYYY @ HH:mm')}
                              </p>
                              <p className={`alert alert-${actColor ?? 'info'} outline py-1 px-2 mt-2 f-w-500 d-inline-block rounded`} style={{ fontSize: 10 }}>{actLabel ?? '-'}</p>
                            </div>
                          </div>
                        </td>
                        <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`} style={{ maxWidth: 250, minWidth: 250 }}>
                          <div>
                            <p className='alert alert rounded py-1 px-2 mr-1 d-inline-block txt-light my-0 text-truncate f-w-500 small' style={{ backgroundColor: subject?.color }}>
                              {subject?.title} <span className='badge badge-light p-1' style={{ color: subject?.color }}>ID: {item?.subject_id}</span>
                            </p>
                          </div>
                          {subject?.component && React.cloneElement(subject?.component, { data: { ...getLogsValue(item), subject: subject }, ...addtionalProps })}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </>
          )}
      <div className={`card-footer ${redux.darkModeOn ? 'bg-dark' : ''}`}>
        <PaginationDiv data={data} onChange={handlePage} />
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(ModalAdminActivityLogsComponent)
